<template>
  <div class="layout-1">
    <div class="create-header create-block">
      <div class="x-input-item">
        <div class="input-name">Название</div>
        <div class="input-content">
          <textInput placeholder="Назовите автоматизацию"/>
        </div>
      </div>
      <div class="x-input-item x-input-date">
        <div class="input-name">Когда отправлять</div>
        <div class="input-content max-w-180">
          <Multiselect
            v-model="form.when_send"
            :options="timer"
            placeholder="Сразу"
            :canClear="false"
          />
        </div>
        <textInput class="input-hours" @onChange="form.when_exact_send = $event" v-if="form.when_send === 'Через' || form.when_send === 'За'"
                   :options="{type: 'integer', min: 1, max: 1000}" default-value="3"/>
        <div v-if="form.when_send === 'Сразу'" class="create-conditions-afterword">в момент наступления события</div>
        <div v-if="form.when_send === 'Через'" class="create-conditions-afterword">часов после наступления события</div>
        <div v-if="form.when_send === 'За'" class="create-conditions-afterword">часов до наступления события</div>
      </div>
      <div class="x-input-item x-input-item-noteTop" :class="{'x-input-EmptyAfterword': !config_event[form.event.name]?.afterword, 'x-input-wrap' : config_event[form.event.name]?.wrap}">
        <div class="input-name">Событие</div>
        <div class="input-content input-content-event">
          <Multiselect
            v-model="form.event.name"
            :options="Object.keys(config_event)"
            placeholder="Выберите событие"
            :canClear="false"
          />
        </div>
        <div class="create-conditions-event">
          <template v-if="config_event[form.event.name]">
            <textInput class="input-hours" v-if="config_event[form.event.name].input?.type === 'integer'"
                       :options="config_event[form.event.name].input || {}" default-value="" @onChange="config_event[form.event.name].inputValue = $event"/>
            <div class="input-content input-multiselect-status" v-if="config_event[form.event.name].input?.type === 'multiselect'">
              <Multiselect
                v-model="config_event[form.event.name].input.inputValue"
                :options="loyal"
                placeholder="Выберете статус"
                :canClear="false"
              />
            </div>
            <template v-if="config_event[form.event.name].note && config_event[form.event.name].input2">
              <div class="create-conditions-afterword" v-if="config_event[form.event.name].afterword2">
                {{ config_event[form.event.name].afterword2 }}
              </div>
              <textInput class="input-hours" v-if="config_event[form.event.name].input2.type === 'integer'"
                         :options="config_event[form.event.name].input2 || {}" default-value="" @onChange="config_event[form.event.name].inputValue2 = $event"/>
            </template>
            <div v-if="config_event[form.event.name].note" class="create-conditions-afterword">

              <div class="note-wrapper">
                <div>
                  {{ config_event[form.event.name].afterword }}
                </div>
                <note note-position="bottom-to-left" :class="{'empty-afterword-note': !config_event[form.event.name].afterword}">
                  <span v-html="config_event[form.event.name].note"></span>
                </note>
              </div>
            </div>
          </template>
        </div>

      </div>
      <div class="x-input-item">
        <div class="input-name">Что сделать</div>
        <div class="input-content">
          <Multiselect
            v-model="form.what_do"
            :options="send"
            :placeholder="send[0]"
            :canClear="false"
          />
        </div>
      </div>
      <div class="create-conditions" v-if="form.what_do === 'Начислить бонусы и отправить сообщение'">
        <div class="create-conditions-bonuses">
          <div class="x-input-item">
            <div class="input-name">Начислить клиенту</div>
            <div class="input-content">
              <textInput default-value="500" :options="{type: 'integer', min: 1}"/>
            </div>
            <div class="create-conditions-afterword">бонусов</div>
          </div>
        </div>
        <div class="create-conditions-remove">
          <div class="x-input-item">
            <div class="input-name">Сгорают</div>
            <div class="input-content">
              <Multiselect
                v-model="form.is_burn"
                :options="burn_bonuses_action"
                placeholder="Да"
                :canClear="false"
              />
            </div>
          </div>
        </div>
        <div class="create-conditions-term" v-if="form.is_burn === 'Да'">
          <div class="x-input-item">
            <div class="input-name">Через</div>
            <div class="input-content">
              <textInput :options="{type: 'integer', min: 0, max: 1000}" default-value="3"/>
            </div>
            <div class="create-conditions-afterword">дня</div>
          </div>
        </div>
      </div>
      <div class="create-header-total">
        В выборку попадают
        <router-link class="link" to="/admin/clients">2 870 клиентов</router-link>
        из 2 870
      </div>
    </div>
    <div class="create-message create-block">
      <div class="create-message-send">
        <div class="note-wrapper">
          <h2 class="title">
            Отправить сообщение
          </h2>
          <note note-position="bottom-to-right">
            Вы можете использовать переменные из списка ниже (автополя). Для каждого клиента переменная принимает значение, актуальное для этого клиента. <br><br> Например, переменная @Имя_клиента позволяет к каждому клиенту обратиться по имени – для каждого будет подставлено его имя. <br><br> Переменные позволяют конструировать сообщения, которые в точности соответствую индивидуальной ситуации каждого конкретного клиента.</note>
        </div>
        <div class="create-message-body">
          <div class="text-wrapper" contenteditable='false'>
            <div class="input-wrapper">
              <div class="input-container">
                <div class="input-clicker input-big" @click="deleteText" ref="superText" contenteditable="true" @input="setTemplate($event, isOnlyMessageSet ? 'template_only_message' : 'template_default')" v-html="isOnlyMessageSet ? config_event[form.event.name]?.template_only_message : config_event[form.event.name]?.template_default">
                </div>
              </div>
            </div>
            <div class="clicker-tech">
              <input
                v-for="autofield in config_event[form.event.name]?.autofields" :key="autofield"
                @click="this.$refs.superText.focus(); insertText(`<span contenteditable='false' class='link clicker-link'>${autofield}</span>`)"
                class="link clicker-tech-link" type="button" :value="autofield">
            </div>
          </div>
        </div>
        <div class="create-message-button">
          <x-button color="purple-gradient" size="small">Отправить тест</x-button>
        </div>
      </div>
    </div>
    <template v-if="!isOnlyMessageSet">
      <div class="create-message-reminder create-block">
        <div class="x-input-item">
          <div class="input-name">Сообщить о скором сгорании бонусов</div>
          <div class="input-content">
            <Multiselect
              v-model="form.remind_soon_burn"
              :options="reminder"
              placeholder="Да"
              :canClear="false"
            />
          </div>
        </div>
        <div v-if="form.remind_soon_burn === 'Да'" class="create-message-send">
          <h2 class="title">
            Отправить сообщение
            <note></note>
          </h2>
          <div class="create-message-body">
            <div class="text-wrapper" contenteditable='false'>
              <div class="input-wrapper">
                <div class="input-container">
                  <div class="input-clicker" @click="deleteText" ref="superText2" contenteditable="true"
                       @input="setTemplate($event, 'template_soon_burn')"
                       v-html="config_event[form.event.name]?.template_soon_burn"></div>
                </div>
              </div>
              <div class="clicker-tech">
                <input
                  v-for="autofield in config_event[form.event.name]?.autofields" :key="autofield"
                  @click="this.$refs.superText2.focus(); insertText(`<span contenteditable='false' class='link clicker-link'>${autofield}</span>`)"
                  class="link clicker-tech-link" type="button" :value="autofield">
              </div>
            </div>
          </div>
          <div class="create-message-button">
            <x-button color="purple-gradient" size="small">Отправить тест</x-button>
          </div>
        </div>
      </div>
      <div class="create-message-notify create-block">
        <div class="x-input-item">
          <div class="input-name">Сообщить о факте сгорании бонусов</div>
          <div class="input-content">
            <Multiselect
              v-model="form.remind_burned"
              :options="notify"
              placeholder="Да"
              :canClear="false"
            />
          </div>
        </div>
        <div v-if="form.remind_burned === 'Да'" class="create-message-send">
          <h2 class="title">
            Отправить сообщение
            <note></note>
          </h2>
          <div class="create-message-body">
            <div class="text-wrapper" contenteditable='false'>
              <div class="input-wrapper">
                <div class="input-container">
                  <div class="input-clicker" @click="deleteText" ref="superText3" contenteditable="true"
                       @input="setTemplate($event, 'template_burned')"
                       v-html="config_event[form.event.name]?.template_burned"></div>
                </div>
              </div>
              <div class="clicker-tech">
                <input
                  v-for="autofield in config_event[form.event.name]?.autofields" :key="autofield"
                  @click="this.$refs.superText3.focus(); insertText(`<span contenteditable='false' class='link clicker-link'>${autofield}</span>`)"
                  class="link clicker-tech-link" type="button" :value="autofield">
              </div>
            </div>
          </div>
          <div class="create-message-button">
            <x-button color="purple-gradient" size="small">Отправить тест</x-button>
          </div>
        </div>
      </div>
    </template>

    <div class="create-message-footer">
      <div class="create-message-footer__save--wrapper">
        <div class="create-message-footer__save">
          <xButton>Сохранить</xButton>
        </div>
        <button class="link" @click="$router.go(-1)">Закрыть</button>
      </div>
    </div>
  </div>
</template>

<script>
import textInput from '@/components/textInput'
import xButton from '@/components/xButton'
import note from '@/components/note'
import templates from './templates.json'
export default {
  components: {
    textInput,
    xButton,
    note
  },
  name: 'messagesCreateEvents',
  computed: {
    isOnlyMessageSet(){
      return this.form.what_do !== 'Начислить бонусы и отправить сообщение'
    }
  },
  data() {
    return {
      templates: templates,
      form: {
        when_send: 'Сразу',
        when_exact_send: '',
        event: {
          name: '',
          template_default: '',
          template_soon_burn: '',
          template_burned: '',
          template_only_message: ''
        },
        what_do: 'Начислить бонусы и отправить сообщение',
        is_burn: 'Да',
        when_burn: '',
        remind_soon_burn: 'Да',
        remind_burned: 'Да'
      },
      config_event: {
        'Х дней без покупок после вступления': {
          afterword: 'укажите количество дней',
          input: {type: 'integer', min: 1},
          inputValue: ''
        },
        'Х дней без покупок': {
          afterword: 'укажите количество дней',
          input: {type: 'integer', min: 1},
          inputValue: ''
        },
        'Х дней с момента первой покупки': {
          afterword: 'укажите количество дней',
          input: {type: 'integer', min: 1},
          inputValue: ''
        },
        'День рождения': {
          afterword: '',
          input: null,
          inputValue: ''
        },
        'Обычная очередная покупка': {
          afterword: '',
          input: null,
          inputValue: ''
        },
        'Совершение покупки №': {
          afterword: 'укажите номер покупки',
          input: {type: 'integer', min: 1},
          inputValue: ''
        },
        'Совершение покупки на сумму от': {
          afterword: 'укажите сумму',
          input: {type: 'integer', min: 1},
          inputValue: ''
        },
        'Совершение покупки накопительно на общую сумму': {
          afterword: 'укажите сумму',
          input: {type: 'integer', min: 1},
          inputValue: ''
        },
        'Совершение покупки накопительно на общую сумму X за Y дней': {
          afterword: 'укажите за какой период в днях',
          afterword2: 'укажите сумму',
          input: {type: 'integer', min: 1},
          input2: {type: 'integer', min: 1},
          inputValue: '',
          inputValue2: '',
          wrap: true
        },
        'Достижение статуса в системе лояльности': {
          afterword: '',
          input: {type: 'multiselect'},
          inputValue: '',
          wrap: true
        },
        'Клиент отправил команду "Рекомендовать"': {
          afterword: '',
          input: null,
          inputValue: ''
        },
        'Друг по рекомендации прошел регистрацию': {
          afterword: '',
          input: null,
          inputValue: ''
        },
        'Друг по рекомендации сделал покупку': {
          afterword: '',
          input: null,
          inputValue: ''
        },
        'X друзей по рекомендации сделали покупки': {
          afterword: 'укажите количество друзей',
          input: {type: 'integer', min: 1},
          inputValue: ''
        }
      },
      send: [
        'Начислить бонусы и отправить сообщение',
        'Отправить сообщение'
      ],
      timer: [
        'Сразу',
        'Через',
        'За'
      ],
      loyal: [
        'Бронзовый',
        'Серебряный',
        'Золото'
      ],
      burn_bonuses_action: [
        'Да',
        'Нет'
      ],
      reminder: [
        'Да',
        'Нет'
      ],
      notify: [
        'Да',
        'Нет'
      ]
    }
  },
  methods: {
    setTemplate(value, templateName){
      this.form.event[templateName] = value.target.innerHTML
    },
    insertText(html, selectPastedContent) {
      let sel, range
      if (window.getSelection) {
        sel = window.getSelection()
        if (sel.getRangeAt && sel.rangeCount) {
          range = sel.getRangeAt(0)
          range.deleteContents()
          const el = document.createElement('div')
          el.innerHTML = html
          const frag = document.createDocumentFragment();
          let node;
          let lastNode
          while ((node = el.firstChild)) {
            lastNode = frag.appendChild(node)
          }
          const firstNode = frag.firstChild
          range.insertNode(frag)

          if (lastNode) {
            range = range.cloneRange()
            range.setStartAfter(lastNode)
            if (selectPastedContent) {
              range.setStartBefore(firstNode)
            } else {
              range.collapse(true)
            }
            sel.removeAllRanges()
            sel.addRange(range)
          }
        }
      } else if ((sel = document.selection) && sel.type !== 'Control') {
        // IE < 9
        const originalRange = sel.createRange()
        originalRange.collapse(true)
        sel.createRange().pasteHTML(html)
        if (selectPastedContent) {
          range = sel.createRange()
          range.setEndPoint('StartToStart', originalRange)
          range.select()
        }
      }
    },
    deleteText(e) {
      if ((e.path && e.path[0]?.className === 'link clicker-link') || e.target.className === 'link clicker-link') {
        e.path ? e.path[0].innerHTML = '' : e.target.innerHTML = ''
      }
    }
  },
  created() {
    const keys = Object.keys(this.config_event)
    keys.forEach((key) => {
      this.config_event[key].note = templates.config_event[key].note
      this.config_event[key].template_default = templates.config_event[key].template_default
      this.config_event[key].template_soon_burn = templates.config_event[key].template_soon_burn
      this.config_event[key].template_burned = templates.config_event[key].template_burned
      this.config_event[key].template_only_message = templates.config_event[key].template_only_message
      this.config_event[key].autofields = templates.config_event[key].autofields
    })
  }
}
</script>

<style lang="scss" src="../messages/messages.scss" scoped/>
<style lang="scss" src="../../components/textInput/textInput.scss" scoped/>
<style lang="scss" src="./messagesCreateAutomation.scss" scoped/>
